<template>
    <v-container v-if="gauges.filter(f => f.aantal > 0).length > 0">
        <v-card v-for="(g, index) in gauges.filter(f => f.aantal > 0)" :key="index">
            <v-card-title>Aantoonbare vakbekwaamheid mbt functie {{ g.naam }}</v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="5">
                            <vue-gauge :options="g.gaugeOptions" :refid="`g${index}`"></vue-gauge>
                        </v-col>
                        <v-col cols="5">
                            <div v-for="(item, index2) in gaugeData.filter(f => f.id == g.id)" :key="index2">
                                <p>
                                    Aantal personen die functie kunnen vervullen : {{ item.aantal }}
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>

            </v-card-text>
        </v-card>
    </v-container>
    <v-container v-else>
        <p>Mate van vakbekwaamheid kan nog niet bepaald worden.</p>
    </v-container>
</template>

<script>

import VueGauge from 'vue-gauge';
import client from "api-client";

export default {
    name: "AuditDashboard",

    data() {
        return {
            gaugeData: [],
            gaugeOptions: {
                'needleValue': 0, 'arcDelimiters': [50, 60], arcColors: ['rgb(255,84,84)', 'rgb(239,214,19)', 'rgb(61,204,91)']
            }
        }
    },
    components: {
        vueGauge: VueGauge
    },
    methods: {
        getGauges() {
            client.fetchGraphGauges2(this.$route.params.instantieId, this.$route.params.auditId).then((data) => {
                this.gaugeData = data;
            });
        },
    },
    computed: {
        gauges: function () {
            if(this.gaugeData!=null){
                return this.gaugeData.map(row => ({ id: row.id, naam: row.functieNaam, aantal: row.aantal, gaugeOptions: { ...this.gaugeOptions, needleValue: row.perc == null ? 0 : row.perc } }))
            }
            return []
        }
    },
    created() {
        this.getGauges();
    }

}
</script>